import { Button, Col, Form, Row, Select, Space } from "antd";
import { observer } from "mobx-react-lite";
import { Controller } from "react-hook-form";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormInput, FormItem, FormSelect } from "@/ui/_common_/form-helpers";
import { FormSpinner } from "@/ui/_common_/form-spinner";

import { EditActionFormState } from "./edit-form.state";

type Props = {
  state: EditActionFormState;
};

export const EditActionFormView = observer<Props>(({ state }) => {
  const { form, handleSubmit, mutation, websitesQuery, websites } = state;

  if (websitesQuery.isPending) {
    return <FormSpinner />;
  }

  return (
    <Space direction="vertical">
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={handleSubmit}
      >
        <ErrorsFormatter queries={[mutation]} />
        <FormItem form={form} path="type" label={"Type"}>
          <FormSelect
            form={form}
            path="type"
            placeholder={"Select type"}
            options={[
              {
                label: "Main",
                value: "main",
              },
              {
                label: "Game",
                value: "game",
              },
              {
                label: "Document",
                value: "document",
              },
              {
                label: "Contacts",
                value: "contacts",
              },
              {
                label: "Category",
                value: "category",
              },
              {
                label: "Sport history",
                value: "sport_history",
              },
            ]}
          />
        </FormItem>
        <FormItem form={form} path="domainId" label={"Domain"}>
          <Controller
            control={form.control}
            name="domainId"
            render={({ field }) => (
              <Select
                value={field.value}
                onChange={(value) => field.onChange(value || null)}
                options={websites.map((website) => ({
                  label: website.domainName,
                  value: website.id,
                }))}
                placeholder={"Select domain"}
                loading={websitesQuery.isPending}
                allowClear
              />
            )}
          />
        </FormItem>
        <FormItem form={form} path="data" label={"Data"}>
          <FormInput form={form} path="data" placeholder={"Enter data"} />
        </FormItem>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={mutation.isPending}
            >
              {"Save"}
            </Button>
          </Col>
        </Row>
      </Form>
    </Space>
  );
});
