import { Modal } from "antd";
import { observer } from "mobx-react-lite";

import { EditCategoryForm } from "./form";
import { EditCategoryModalState } from "./modal.state";

type Props = { state: EditCategoryModalState };

export const EditCategoryModalView = observer<Props>(({ state }) => {
  return (
    <Modal
      open={state.opened}
      onCancel={state.close}
      title={"Edit game category"}
      footer={null}
      destroyOnClose
      width={720}
    >
      {!!state.id && <EditCategoryForm id={state.id} />}
    </Modal>
  );
});
