import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import {
  GameCategoryActionAdded,
  OpenEditGameCategoryActionModal,
} from "@/events";
import { useGlobalStore } from "@/stores";

export function useEditActionModalState() {
  const intl = useIntl();
  const [opened, setOpened] = useState(false);
  const [payload, setPayload] =
    useState<OpenEditGameCategoryActionModal["payload"]>();

  const { eventBusService } = useGlobalStore();

  useEffect(() => {
    const listener = (event: OpenEditGameCategoryActionModal) => {
      setOpened(true);
      setPayload(event.payload);
    };

    eventBusService.subscribe(OpenEditGameCategoryActionModal, listener);

    return () => {
      eventBusService.unsubscribe(OpenEditGameCategoryActionModal, listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function close() {
    setOpened(false);
    setPayload(undefined);
  }

  useEffect(() => {
    eventBusService.subscribe(GameCategoryActionAdded, close);
    return () => eventBusService.unsubscribe(GameCategoryActionAdded, close);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { intl, opened, close, payload };
}

export type EditActionModalState = ReturnType<typeof useEditActionModalState>;
