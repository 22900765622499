import { GameCategoryAction, TSportReportItem } from "./api";
import { Event } from "./services/event-bus.service";
import { Entity, SweepstakeOperationType } from "./types";

type Empty = Record<string, never>;

export class CheckPlayerBonuses extends Event<{
  playerId: number;
  kioskId?: number;
}> {
  name = "CheckPlayerBonuses";
}

export class PlayerBonusesChecked extends Event<{ playerId: number }> {
  name = "PlayerBonusesChecked";
}

export class DepositPlayerBalance extends Event<{
  playerId: number;
  kioskId?: number;
}> {
  name = "DepositPlayerBalance";
}

export class PlayerBalanceDeposited extends Event<{ playerId: number }> {
  name = "PlayerBalanceDeposited";
}

export class PlayerBalanceWithdrawn extends Event<{ playerId: number }> {
  name = "PlayerBalanceWithdrawn";
}

export class WithdrawPlayerBalance extends Event<{
  playerId: number;
  kioskId?: number;
}> {
  name = "WithdrawPlayerBalance";
}

export class ExchangePlayerBalance extends Event<{
  playerId: number;
  operationType: SweepstakeOperationType;
}> {
  name = "ExchangePlayerBalance";
}

export class PlayerBalanceExchanged extends Event<{ playerId: number }> {
  name = "PlayerBalanceExchanged";
}

export class UpdateClient extends Event<{ clientId: number }> {
  name = "UpdateClient";
}

export class ClientUpdated extends Event<{ clientId: number }> {
  name = "ClientUpdated";
}

export class UpdateAgent extends Event<{ agentId: number }> {
  name = "UpdateAgent";
}

export class AgentUpdated extends Event<{ agentId: number }> {
  name = "AgentUpdated";
}

export class UpdateHall extends Event<{ hallId: number }> {
  name = "UpdateHall";
}

export class HallUpdated extends Event<{ hallId: number }> {
  name = "HallUpdated";
}

export class CreateCashier extends Event<{ hallId: number }> {
  name = "CreateCashier";
}

export class CashierCreated extends Event<Empty> {
  name = "CashierCreated";
}

export class CreatePlayer extends Event<{ hallId: number }> {
  name = "CreatePlayer";
}

export class PlayerCreated extends Event<Empty> {
  name = "PlayerCreated";
}

export class UpdatePlayer extends Event<{ playerId: number }> {
  name = "UpdatePlayer";
}

export class PlayerUpdated extends Event<Empty> {
  name = "PlayerUpdated";
}

export class CreateKiosk extends Event<{ hallId: number }> {
  name = "CreateKiosk";
}

export class KioskCreated extends Event<Empty> {
  name = "KioskCreated";
}

export class OpenEditKioskModal extends Event<{
  clientId: number;
  hallId: number;
  kioskId: number;
}> {
  name = "OpenEditKioskModal";
}

export class CloseEditKioskModal extends Event<Empty> {
  name = "CloseEditKioskModal";
}

export class OpenKiosksSettings extends Event<{
  clientId: number;
  hallId: number;
}> {
  name = "OpenKiosksSettings";
}

export class CloseKiosksSettings extends Event<Empty> {
  name = "CloseKiosksSettings";
}

export class OpenAddKioskAccessModal extends Event<{
  clientId: number;
  hallId: number;
  kioskId?: number;
}> {
  name = "OpenAddKioskAccessModal";
}

export class CloseAddKioskAccessModal extends Event<Empty> {
  name = "CloseAddKioskAccessModal";
}

export class OpenEditKioskAccessModal extends Event<{
  id: number;
  clientId: number;
  hallId: number;
  kioskId?: number;
}> {
  name = "OpenEditKioskAccessModal";
}

export class CloseEditKioskAccessModal extends Event<Empty> {
  name = "CloseEditKioskAccessModal";
}

export class KioskAccessRemoved extends Event<Empty> {
  name = "KioskAccessRemoved";
}

export class BonusUpdated extends Event<{ bonusId: number }> {
  name = "BonusUpdated";
}

export class EditCashierLimit extends Event<{ cashierId: number }> {
  name = "EditCashierLimit";
}

export class CashierLimitEdited extends Event<{ cashierId: number }> {
  name = "CashierUpdated";
}

export class StartShift extends Event<{ cashierId: number }> {
  name = "StartShift";
}

export class ShiftStarted extends Event<{ cashierId: number }> {
  name = "ShiftStarted";
}

export class DepositCashRegister extends Event<{ cashierId: number }> {
  name = "DepositCashRegister";
}

export class CashRegisterDeposited extends Event<{ cashierId: number }> {
  name = "CashRegisterDeposited";
}

export class WithdrawCashRegister extends Event<{ cashierId: number }> {
  name = "WithdrawCashRegister";
}

export class CashRegisterWithdrawn extends Event<{ cashierId: number }> {
  name = "CashRegisterWithdrawn";
}

export class FinishShift extends Event<{ cashierId: number }> {
  name = "FinishShift";
}

export class ShiftFinished extends Event<{ cashierId: number }> {
  name = "ShiftFinished";
}

export class CreateArticle extends Event<Empty> {
  name = "CreateArticle";
}

export class ArticleCreated extends Event<Empty> {
  name = "ArticleCreated";
}

export class UpdateArticle extends Event<{ articleId: number }> {
  name = "UpdateArticle";
}

export class ArticleUpdated extends Event<{ articleId: number }> {
  name = "ArticleUpdated";
}

export class CreateBrand extends Event<Empty> {
  name = "CreateBrand";
}

export class BrandCreated extends Event<Empty> {
  name = "BrandCreated";
}

export class CreateGame extends Event<{
  providerId?: number;
  brandId?: number;
}> {
  name = "CreateGame";
}

export class GameCreated extends Event<Empty> {
  name = "GameCreated";
}

export class UpdateGame extends Event<{ id: number }> {
  name = "UpdateGame";
}

export class GameUpdated extends Event<Empty> {
  name = "GameUpdated";
}

export class EditGameOrder extends Event<{
  agentId: number;
  gameId: number;
  gameOrder: number | null;
}> {
  name = "EditGameOrder";
}

export class GameOrderEdited extends Event<{
  agentId: number;
  gameId: number;
}> {
  name = "GameOrderEdited";
}

export class HallPanicUpdated extends Event<{ hallId: number }> {
  name = "HallPanicUpdated";
}

export class CreateVoucher extends Event<{ hallId: number }> {
  name = "CreateVoucher";
}

export class VoucherCreated extends Event<Empty> {
  name = "VoucherCreated";
}

export class OpenAddBannerModal extends Event<{
  sliderId: number;
  entity: Entity | null;
}> {
  name = "OpenAddBannerModal";
}

export class CloseAddBannerModal extends Event<Empty> {
  name = "CloseAddBannerModal";
}

export class OpenEditBannerModal extends Event<{
  id: number;
  entity: Entity | null;
}> {
  name = "OpenEditBannerModal";
}

export class CloseEditBannerModal extends Event<Empty> {
  name = "CloseEditBannerModal";
}

export class OpenSportReportDetailsModal extends Event<{
  item: TSportReportItem;
}> {
  name = "OpenSportReportDetailsModal";
}

export class CloseSportReportDetailsModal extends Event<Empty> {
  name = "CloseSportReportDetailsModal";
}

export class OpenNewCategoryModal extends Event<{ parentId: number | null }> {
  name = "OpenNewCategoryModal";
}

export class CloseNewCategoryModal extends Event<Empty> {
  name = "CloseNewCategoryModal";
}

export class OpenEditCategoryModal extends Event<{ id: number }> {
  name = "OpenEditCategoryModal";
}

export class CloseEditCategoryModal extends Event<Empty> {
  name = "CloseEditCategoryModal";
}

export class CategoryCreated extends Event<Empty> {
  name = "CategoryCreated";
}

export class CategoryEdited extends Event<Empty> {
  name = "CategoryEdited";
}

export class CategoryDeleted extends Event<Empty> {
  name = "CategoryDeleted";
}

export class SportCouponPayout extends Event<Empty> {
  name = "SportCouponPayout";
}

export class OpenImportPlayersModal extends Event<{
  hallId: number;
}> {
  name = "OpenImportPlayersModal";
}

export class PlayersImported extends Event<Empty> {
  name = "PlayersImported";
}

export class OpenNewWebsiteDomainModal extends Event<Empty> {
  name = "OpenNewWebsiteDomainModal";
}

export class WebsiteDomainCreated extends Event<Empty> {
  name = "WebsiteDomainCreated";
}

export class OpenEditWebsiteDomainModal extends Event<{ id: number }> {
  name = "OpenEditWebsiteDomainModal";
}

export class WebsiteDomainEdited extends Event<Empty> {
  name = "WebsiteDomainEdited";
}

export class OpenNewWebsiteTemplateModal extends Event<Empty> {
  name = "OpenNewWebsiteTemplateModal";
}

export class WebsiteTemplateCreated extends Event<Empty> {
  name = "WebsiteTemplateCreated";
}

export class OpenEditWebsiteTemplateModal extends Event<{ id: number }> {
  name = "OpenEditWebsiteTemplateModal";
}

export class WebsiteTemplateEdited extends Event<Empty> {
  name = "WebsiteTemplateEdited";
}

export class CreatePartner extends Event<Empty> {
  name = "CreatePartner";
}

export class PartnerCreated extends Event<Empty> {
  name = "PartnerCreated";
}

export class EditPartner extends Event<{ id: number }> {
  name = "EditPartner";
}

export class PartnerEdited extends Event<Empty> {
  name = "PartnerEdited";
}

export class CreateClub extends Event<{ partnerId: number }> {
  name = "CreateClub";
}

export class ClubCreated extends Event<Empty> {
  name = "ClubCreated";
}

export class EditClub extends Event<{ id: number }> {
  name = "EditClub";
}

export class ClubEdited extends Event<Empty> {
  name = "ClubEdited";
}

export class OpenCategoryGamesModal extends Event<{
  id: number;
  name: string;
}> {
  name = "OpenCategoryGamesModal";
}

export class CloseCategoryGamesModal extends Event<Empty> {
  name = "CloseCategoryGamesModal";
}

export class OpenAddDocumentModal extends Event<{ entity: Entity }> {
  name = "OpenAddDocumentModal";
}

export class CloseAddDocumentModal extends Event<Empty> {
  name = "CloseAddDocumentModal";
}

export class OpenEditDocumentModal extends Event<{ id: number }> {
  name = "OpenEditDocumentModal";
}

export class CloseEditDocumentModal extends Event<Empty> {
  name = "CloseEditDocumentModal";
}

export class DocumentDeleted extends Event<Empty> {
  name = "DocumentDeleted";
}

export class DocumentTranslationAdded extends Event<{ code: string }> {
  name = "DocumentTranslationAdded";
}

export class DocumentTranslationUpdated extends Event<Empty> {
  name = "DocumentTranslationUpdated";
}

export class DocumentTranslationDeleted extends Event<Empty> {
  name = "DocumentTranslationDeleted";
}

export class OpenAddGameCategoryActionModal extends Event<{
  categoryId: number;
}> {
  name = "OpenAddGameCategoryActionModal";
}

export class GameCategoryActionAdded extends Event<Empty> {
  name = "GameCategoryActionAdded";
}

export class OpenEditGameCategoryActionModal extends Event<{
  categoryId: number;
  action: GameCategoryAction;
}> {
  name = "OpenEditGameCategoryActionModal";
}

export class GameCategoryActionEdited extends Event<Empty> {
  name = "GameCategoryActionEdited";
}

export class GameCategoryActionDeleted extends Event<Empty> {
  name = "GameCategoryActionDeleted";
}
