import { observer } from "mobx-react-lite";

import { OpenEditGameCategoryActionModal } from "@/events";

import { useEditActionFormState } from "./edit-form.state";
import { EditActionFormView } from "./edit-form.view";

export type EditActionFormProps = {
  payload: OpenEditGameCategoryActionModal["payload"];
};

export const EditActionForm = observer<EditActionFormProps>((props) => {
  const state = useEditActionFormState(props);
  return <EditActionFormView state={state} />;
});
