import yup from "@/yup";

export function useEditActionFormSchema() {
  const types = [
    "main",
    "game",
    "document",
    "contacts",
    "category",
    "sport_history",
  ] as const;

  return yup.object({
    type: yup
      .mixed<(typeof types)[number]>()
      .oneOf([...types])
      .required(),
    domainId: yup.number().integer().nullable().defined(),
    data: yup.string().nullable().defined(),
  });
}

export type EditActionFormSchema = yup.InferType<
  ReturnType<typeof useEditActionFormSchema>
>;
