import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { GameCategoryAPI, WebsiteAPI } from "@/api";
import { GameCategoryActionAdded } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

import { AddActionFormProps } from "./add-form";
import { AddActionFormSchema, useAddActionFormSchema } from "./add-form.schema";

export function useAddActionFormState({ categoryId }: AddActionFormProps) {
  const intl = useIntl();

  const schema = useAddActionFormSchema();
  const resolver = yupResolver(schema);
  const form = useForm<AddActionFormSchema>({
    resolver,
    defaultValues: {
      type: "main",
      domainId: null,
      data: null,
    },
  });

  const { eventBusService } = useGlobalStore();

  const mutation = useMemo(() => {
    return new Query(async (action: AddActionFormSchema) => {
      await GameCategoryAPI.upsertActions({ categoryId, actions: [action] });

      notification.success({
        message: "",
        description: "The action has been added.",
      });

      eventBusService.publish(new GameCategoryActionAdded({}));
    });
  }, [eventBusService, categoryId]);

  const handleSubmit = form.handleSubmit((data) => {
    mutation.submit(data);
  });

  const websitesQuery = useMemo(() => {
    const query = new Query(WebsiteAPI.getAll);
    query.submit();
    return query;
  }, []);

  const websites = useMemo(
    () => websitesQuery.data?.data ?? [],
    [websitesQuery.data?.data],
  );

  return {
    intl,
    form,
    handleSubmit,
    mutation,
    websitesQuery,
    websites,
  };
}

export type AddActionFormState = ReturnType<typeof useAddActionFormState>;
