import { observer } from "mobx-react-lite";

import { useAddActionFormState } from "./add-form.state";
import { AddActionFormView } from "./add-form.view";

export type AddActionFormProps = {
  categoryId: number;
};

export const AddActionForm = observer<AddActionFormProps>((props) => {
  const state = useAddActionFormState(props);
  return <AddActionFormView state={state} />;
});
