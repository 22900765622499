import { Modal } from "antd";
import { observer } from "mobx-react-lite";

import { EditActionForm } from "./edit-form";
import { EditActionModalState } from "./edit-modal.state";

type Props = { state: EditActionModalState };

export const EditActionModalView = observer<Props>(({ state }) => {
  return (
    <Modal
      open={state.opened}
      onCancel={state.close}
      title={"Edit action"}
      footer={null}
      destroyOnClose
      width={720}
    >
      {!!state.payload && <EditActionForm payload={state.payload} />}
    </Modal>
  );
});
