import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import {
  GameCategoryActionAdded,
  OpenAddGameCategoryActionModal,
} from "@/events";
import { useGlobalStore } from "@/stores";

export function useAddActionModalState() {
  const intl = useIntl();
  const [opened, setOpened] = useState(false);
  const [id, setId] = useState<number>();

  const { eventBusService } = useGlobalStore();

  useEffect(() => {
    const listener = (event: OpenAddGameCategoryActionModal) => {
      setOpened(true);
      setId(event.payload.categoryId);
    };

    eventBusService.subscribe(OpenAddGameCategoryActionModal, listener);

    return () => {
      eventBusService.unsubscribe(OpenAddGameCategoryActionModal, listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function close() {
    setOpened(false);
    setId(undefined);
  }

  useEffect(() => {
    eventBusService.subscribe(GameCategoryActionAdded, close);
    return () => eventBusService.unsubscribe(GameCategoryActionAdded, close);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { intl, opened, close, id };
}

export type AddActionModalState = ReturnType<typeof useAddActionModalState>;
