import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, message, Row, Space, Table, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";

import { GameCategoryAction, GameCategoryAPI } from "@/api";
import {
  GameCategoryActionAdded,
  GameCategoryActionDeleted,
  OpenAddGameCategoryActionModal,
  OpenEditGameCategoryActionModal,
} from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

import { AddActionModal } from "./add-modal";
import { EditActionModal } from "./edit-modal";

type Props = {
  categoryId: number;
  initialActions: GameCategoryAction[];
};

export const ActionsTable = observer<Props>(
  ({ categoryId, initialActions }) => {
    const { eventBusService } = useGlobalStore();

    const query = useMemo(() => {
      const query = new Query(GameCategoryAPI.getById);

      query.submit({ id: categoryId });

      return query;

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryId]);

    useEffect(() => {
      const submit = () => query.submit({ id: categoryId });

      eventBusService.subscribe(GameCategoryActionAdded, submit);
      eventBusService.subscribe(GameCategoryActionDeleted, submit);
      return () => {
        eventBusService.unsubscribe(GameCategoryActionAdded, submit);
        eventBusService.unsubscribe(GameCategoryActionDeleted, submit);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const actions = query.data?.actions ?? initialActions;

    const openAddActionModal = () => {
      eventBusService.publish(
        new OpenAddGameCategoryActionModal({ categoryId }),
      );
    };

    const openEditActionModal = (action: GameCategoryAction) => {
      eventBusService.publish(
        new OpenEditGameCategoryActionModal({ categoryId, action }),
      );
    };

    return (
      <Space direction="vertical">
        <AddActionModal />
        <EditActionModal />
        <Typography.Title level={5}>Actions</Typography.Title>
        <Table
          size="small"
          bordered
          dataSource={actions}
          pagination={false}
          rowKey="id"
          columns={[
            {
              dataIndex: "id",
              title: "ID",
            },
            {
              dataIndex: "type",
              title: "Action",
            },
            {
              dataIndex: "domain",
              title: "Domain",
              render: (domainId) => domainId ?? "—",
            },
            {
              dataIndex: "data",
              title: "Details",
              render: (data) => data ?? "—",
            },
            {
              align: "right",
              title: (
                <Row justify="end">
                  <Button
                    size="small"
                    icon={<PlusOutlined />}
                    onClick={openAddActionModal}
                  />
                </Row>
              ),
              render: (_, action) => (
                <Row justify="end" gutter={8}>
                  <Col>
                    <DeleteAction
                      categoryId={categoryId}
                      actionId={action.id}
                    />
                  </Col>
                  <Col>
                    <Button
                      icon={<EditOutlined />}
                      size="small"
                      onClick={() => openEditActionModal(action)}
                    />
                  </Col>
                </Row>
              ),
            },
          ]}
        />
      </Space>
    );
  },
);

type DeleteActionProps = {
  categoryId: number;
  actionId: number;
};

const DeleteAction = observer<DeleteActionProps>(({ categoryId, actionId }) => {
  const { eventBusService } = useGlobalStore();

  const mutation = useMemo(() => {
    return new Query(async () => {
      await GameCategoryAPI.deleteActions({
        categoryId,
        actionsIds: [actionId],
      });

      message.success("The action has been deleted.");

      eventBusService.publish(new GameCategoryActionDeleted({}));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, actionId]);

  return (
    <Button
      size="small"
      icon={<DeleteOutlined />}
      loading={mutation.isPending}
      onClick={() => mutation.submit({})}
    />
  );
});
