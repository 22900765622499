import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { GameCategoryAPI, WebsiteAPI } from "@/api";
import { GameCategoryActionEdited } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

import { EditActionFormProps } from "./edit-form";
import {
  EditActionFormSchema,
  useEditActionFormSchema,
} from "./edit-form.schema";

export function useEditActionFormState({ payload }: EditActionFormProps) {
  const intl = useIntl();

  const schema = useEditActionFormSchema();
  const resolver = yupResolver(schema);
  const form = useForm<EditActionFormSchema>({
    resolver,
    defaultValues: { type: "main", domainId: null, data: null },
  });

  useEffect(() => {
    form.reset(payload.action);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  const { eventBusService } = useGlobalStore();

  const mutation = useMemo(() => {
    return new Query(async (action: EditActionFormSchema) => {
      await GameCategoryAPI.upsertActions({
        categoryId: payload.categoryId,
        actions: [action],
      });

      notification.success({
        message: "",
        description: "The action has been updated.",
      });

      eventBusService.publish(new GameCategoryActionEdited({}));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload.categoryId]);

  const handleSubmit = form.handleSubmit((data) => {
    mutation.submit(data);
  });

  const websitesQuery = useMemo(() => {
    const query = new Query(WebsiteAPI.getAll);
    query.submit();
    return query;
  }, []);

  const websites = useMemo(
    () => websitesQuery.data?.data ?? [],
    [websitesQuery.data?.data],
  );

  return {
    intl,
    form,
    handleSubmit,
    mutation,
    websitesQuery,
    websites,
  };
}

export type EditActionFormState = ReturnType<typeof useEditActionFormState>;
