import MethodBuilder from "../method-builder";
import { GameCategory, LocalizedStringMap, TPaging } from "../types";

export class GameCategoryAPI {
  static getById = new MethodBuilder<{ id: number }, GameCategory>()
    .withName("GameCategory.getById")
    .build();

  static getAll = new MethodBuilder<
    { searchQuery?: string; orderBy?: ["id", "asc" | "desc"] },
    { data: GameCategory[]; total: number }
  >()
    .withName("GameCategory.getAll")
    .build();

  static create = new MethodBuilder<
    {
      names: LocalizedStringMap;
      isActive: boolean;
      order: number;
      parentId: number | null;
      tag: "header" | "menu" | "bottom" | null;
    },
    GameCategory
  >()
    .withName("GameCategory.create")
    .build();

  static update = new MethodBuilder<
    {
      id: number;
      isActive?: boolean;
      order?: number;
      tag?: "header" | "menu" | "bottom" | null;
      names?: LocalizedStringMap;
      domainIdsBlacklist?: number[];
      domainIdsWhitelist?: number[];
    },
    void
  >()
    .withName("GameCategory.update")
    .build();

  static delete = new MethodBuilder<{ id: number }, void>()
    .withName("GameCategory.delete")
    .build();

  static filter = new MethodBuilder<
    { searchQuery?: string; paging?: TPaging },
    { data: GameCategory[]; total: number }
  >()
    .withName("GameCategory.filter")
    .build();

  static updateGames = new MethodBuilder<
    {
      categoryId: number;
      gameIds: number[];
    },
    void
  >()
    .withName("GameCategory.updateGames")
    .build();

  static upsertActions = new MethodBuilder<
    {
      categoryId: number;
      actions: {
        domainId: number | null;
        type:
          | "main"
          | "game"
          | "document"
          | "contacts"
          | "category"
          | "sport_history";
        data: string | null;
      }[];
    },
    void
  >()
    .withName("GameCategory.upsertActions")
    .build();

  static deleteActions = new MethodBuilder<
    {
      categoryId: number;
      actionsIds: number[];
    },
    void
  >()
    .withName("GameCategory.deleteActions")
    .build();
}
